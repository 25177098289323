<template>
    <div>

        <!-- Form: Info Form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- Image -->
                    <b-col md="12">
                        <div id="preview">
                            <img v-if="url" class="img-fluid" :src="url" width="350" alt="image" />
                        </div>
                        <b-form-group label-for="image">
                            <template #label>
                                Imagen <strong>(Formato: webp) (1280x1280)</strong>
                            </template>
                            <b-form-file id="image" v-model="file" placeholder="Elija un archivo ..." @change="onFileChange"
                                accept=".webp" />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">                
                        <b-alert  variant="info" :show="true">
                            <div class="alert-body">
                                <ul class="mb-0">
                                    <li>
                                        Comprime y optimiza tus imágenes en 
                                        <b-link
                                            class="alert-link"
                                            href="https://tinypng.com/"
                                            target="_blank"
                                        >
                                           Tinify
                                        </b-link>
                                    </li>
                                    <li>
                                        Convierte tus imágenes a .webp en 
                                        <b-link
                                            class="alert-link"
                                            href="https://pixelied.com/convert/jpg-converter"
                                            target="_blank"
                                        >
                                            Pixelied
                                        </b-link>
                                    </li>
                                </ul>
                            </div>
                        </b-alert>
                    </b-col>

                    <!-- Date -->
                    <b-col cols="12" md="2">
                        <b-form-group label="Fecha de publicación" label-for="date">
                            <validation-provider #default="{ errors }" rules="required" name="fecha del blog">
                                <flat-pickr id="date" v-model="date" class="form-control" :config="config"
                                    placeholder="YYYY-MM-DD" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Product Type -->
                    <b-col
                        cols="12"
                        md="5"
                    >
                        <b-form-group
                            label="Tipo(s) de producto"
                            label-for="product-type"
                        >
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="tipo de producto"
                            >
                                <v-select
                                    id="product-type"
                                    v-model="blogData.product_type"
                                    :options="product_typeOptions"
                                    multiple
                                    label="name"
                                    :selectable="() => blogData.product_type && blogData.product_type.length < 3"
                                    :state="errors.length > 0 ? false:null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>					
                        </b-form-group>
                    </b-col>

                    <!-- Tags -->
                    <b-col cols="12" md="5">
                        <b-form-group label="Etiquetas" label-for="tags">
                            <v-select id="tags" placeholder="Agregar Etiquetas" v-model="blogData.tags" multiple taggable
                                push-tags label="title" />
                        </b-form-group>
                    </b-col>

                    <!-- Author -->
                    <b-col md="12">
                        <b-form-group label="Autor" label-for="author">
                            <validation-provider #default="{ errors }" rules="required" name="autor">
                                <b-form-input id="author" v-model="blogData.author"
                                    :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Title -->
                    <b-col md="12">
                        <b-form-group label="Título" label-for="title">
                            <validation-provider #default="{ errors }" rules="required" name="título">
                                <b-form-input id="title" v-model="blogData.title" placeholder="Título del blog ..."
                                    :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Short Description -->
                    <b-col cols="12">
                        <b-form-group label="Descripción (Resumen)" label-for="short-description">
                            <validation-provider #default="{ errors }" rules="required" name="descripción corta">
                                <b-textarea id="short-description" v-model="blogData.shortDescription"
                                    :state="(errors.length > 0 ? false : null)" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Long Description -->
                    <b-col cols="12">
                        <b-form-group label="Contenido" label-for="long-description">
                            <validation-provider #default="{ errors }" rules="required" name="contenido">
                                <quill-editor id="long-description" v-model="blogData.longDescription" :options="snowOption"
                                    :state="(errors.length > 0 ? false : null)" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>

        <!-- Action Buttons -->
        <b-button @click="updateInformation" variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'">
            ACTUALIZAR
        </b-button>
    </div>
</template>

<script>

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Spanish } from 'flatpickr/dist/l10n/es';
import flatPickr from 'vue-flatpickr-component';
import { quillEditor } from 'vue-quill-editor';
import Ripple from 'vue-ripple-directive';
import { required } from '@validations';
import Toast from 'vue-toastification';
import vSelect from 'vue-select';
import moment from 'moment';
import axios from 'axios';
import Vue from 'vue';

Vue.use(Toast);

export default {
    components: {
        ValidationProvider,
        ValidationObserver,

        vSelect,
        flatPickr,
        quillEditor,

        required
    },
    props: {
        blogData: {
            type: Object,
            required: true
        }
    },
    directives: {
        Ripple
    },
    data() {
        return {
            initFile: null,
            file: null,
            url: null,
            date: null,
            tags: [],            
            product_typeOptions: [],

            snowOption: {
                theme: 'snow'
            },
            config: {
                enableTime: false,
                locale: Spanish
            }
        }
    },
    async beforeMount() {
        await this.getProductTypes();
        this.date = moment(this.blogData.date).add(1, 'days').format('YYYY-MM-DD');
        this.url = this.transformImg(this.blogData.image);
        this.initFile = this.blogData.image;
    },
    methods: {
        async getProductTypes(){
				let response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/product-types`);
				this.product_typeOptions = response.data;
			},
        onFileChange(e) {
            if (e.target.files.length) {
                const file = e.target.files[0];
                this.file = file;
                this.url = URL.createObjectURL(file);
            } else {
                this.file = null;
                this.url = this.transformImg(this.initFile);
            }
        },
        transformImg(img) {
            if (img) {
                if (img.includes('http') || img.includes('https')) {
                    return img;
                } else {
                    return require(`@/assets/images/modules/blogs/${img}`);
                }
            } else {
                return null;
            }
        },
        updateInformation() {
            this.$refs.simpleRules.validate()
                .then(success => {
                    if (success) {

                        const productTypeIds = this.blogData.product_type.length > 0 ? this.blogData.product_type.map(item => item._id) : [];

                        let formData = new FormData();

                        formData.append('author', this.blogData.author);
                        formData.append('title', this.blogData.title);
                        formData.append('initFile', this.initFile);
                        formData.append('file', this.file);
                        formData.append('longDescription', this.blogData.longDescription);
                        formData.append('shortDescription', this.blogData.shortDescription);
                        formData.append('date', this.date);
                        formData.append('tags', this.blogData.tags);
                        formData.append('product_type', productTypeIds);

                        axios.put(`${process.env.VUE_APP_HOST}/api/dashboard/blog/${this.blogData._id}/update`, formData, {
                            headers: { 'Content-Type': 'multipart/form-data' }
                        })
                            .then(response => {

                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });

                                this.file = null;
                                this.url = this.transformImg(response.data.image);
                            })
                            .catch((err) => {
                                let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del blog.';
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: message,
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });

                    }
                });
        }
    }
}

</script>

<style scoped>
#preview {
    text-align: center;
}

#preview img {
    padding: .25rem;
    margin-bottom: 1.5rem;
    border-radius: .25rem;
    background-color: #343a40;
    border: 1px solid #dee2e6;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>