var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{attrs:{"id":"preview"}},[(_vm.url)?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.url,"width":"350","alt":"image"}}):_vm._e()]),_c('b-form-group',{attrs:{"label-for":"image"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Imagen "),_c('strong',[_vm._v("(Formato: webp) (1280x1280)")])]},proxy:true}])},[_c('b-form-file',{attrs:{"id":"image","placeholder":"Elija un archivo ...","accept":".webp"},on:{"change":_vm.onFileChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-alert',{attrs:{"variant":"info","show":true}},[_c('div',{staticClass:"alert-body"},[_c('ul',{staticClass:"mb-0"},[_c('li',[_vm._v(" Comprime y optimiza tus imágenes en "),_c('b-link',{staticClass:"alert-link",attrs:{"href":"https://tinypng.com/","target":"_blank"}},[_vm._v(" Tinify ")])],1),_c('li',[_vm._v(" Convierte tus imágenes a .webp en "),_c('b-link',{staticClass:"alert-link",attrs:{"href":"https://pixelied.com/convert/jpg-converter","target":"_blank"}},[_vm._v(" Pixelied ")])],1)])])])],1),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('b-form-group',{attrs:{"label":"Fecha de publicación","label-for":"date"}},[_c('validation-provider',{attrs:{"rules":"required","name":"fecha del blog"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date","config":_vm.config,"placeholder":"YYYY-MM-DD","state":errors.length > 0 ? false : null},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Tipo(s) de producto","label-for":"product-type"}},[_c('validation-provider',{attrs:{"rules":"required","name":"tipo de producto"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"product-type","options":_vm.product_typeOptions,"multiple":"","label":"name","selectable":function () { return _vm.blogData.product_type && _vm.blogData.product_type.length < 3; },"state":errors.length > 0 ? false:null},model:{value:(_vm.blogData.product_type),callback:function ($$v) {_vm.$set(_vm.blogData, "product_type", $$v)},expression:"blogData.product_type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('b-form-group',{attrs:{"label":"Etiquetas","label-for":"tags"}},[_c('v-select',{attrs:{"id":"tags","placeholder":"Agregar Etiquetas","multiple":"","taggable":"","push-tags":"","label":"title"},model:{value:(_vm.blogData.tags),callback:function ($$v) {_vm.$set(_vm.blogData, "tags", $$v)},expression:"blogData.tags"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Autor","label-for":"author"}},[_c('validation-provider',{attrs:{"rules":"required","name":"autor"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"author","state":errors.length > 0 ? false : null},model:{value:(_vm.blogData.author),callback:function ($$v) {_vm.$set(_vm.blogData, "author", $$v)},expression:"blogData.author"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Título","label-for":"title"}},[_c('validation-provider',{attrs:{"rules":"required","name":"título"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"title","placeholder":"Título del blog ...","state":errors.length > 0 ? false : null},model:{value:(_vm.blogData.title),callback:function ($$v) {_vm.$set(_vm.blogData, "title", $$v)},expression:"blogData.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Descripción (Resumen)","label-for":"short-description"}},[_c('validation-provider',{attrs:{"rules":"required","name":"descripción corta"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-textarea',{attrs:{"id":"short-description","state":(errors.length > 0 ? false : null)},model:{value:(_vm.blogData.shortDescription),callback:function ($$v) {_vm.$set(_vm.blogData, "shortDescription", $$v)},expression:"blogData.shortDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Contenido","label-for":"long-description"}},[_c('validation-provider',{attrs:{"rules":"required","name":"contenido"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"id":"long-description","options":_vm.snowOption,"state":(errors.length > 0 ? false : null)},model:{value:(_vm.blogData.longDescription),callback:function ($$v) {_vm.$set(_vm.blogData, "longDescription", $$v)},expression:"blogData.longDescription"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs'},on:{"click":_vm.updateInformation}},[_vm._v(" ACTUALIZAR ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }